<template>
  <div v-if="data" class="person" :class="{ 'person--small': small }">
    <div v-if="structure" class="person__image">
      <ImgComponent :img="data.img" width="400" height="400" />
    </div>
    <router-link
      v-else
      :to="{ name: 'ministry-management-open', params: { id: data.id } }"
      class="person__image"
    >
      <ImgComponent :img="data.img" width="400" height="400" />
    </router-link>
    <div class="person__content">
      <div v-if="structure" class="person__name">
        {{ data.surname ? data.surname : "" }} {{ data.name ? data.name : "" }} {{ data.patronymic ? data.patronymic : "" }}
      </div>
      <router-link
        v-else
        :to="{ name: 'ministry-management-open', params: { id: data.id } }"
        class="person__name"
      >
        {{ data.surname }} {{ data.name }} {{ data.patronymic }}
      </router-link>
      <span class="person__title" v-if="data.post">{{ data.post.title }}</span>
      <ul class="person__contacts" v-if="data.phone || data.email">
        <li v-if="data.phone">
          <a :href="`tel:${data.phone}`">
            <IconComponent category="vuesax-bold" name="call" />
            <span>{{ data.phone }}</span>
          </a>
        </li>
        <li v-if="data.email">
          <a :href="`mailto:${data.email}`">
            <IconComponent category="vuesax-bold" name="sms" />
            <span>{{ data.email }}</span>
          </a>
        </li>
      </ul>
      <div class="person__biography" v-if="data.description">
        <span class="person__biography-title">Биография</span>
        <div class="person__biography-content">
          <EditorJSComponent :text="data | getArticle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "PersonComponent",
  props: {
    data: Object,
    small: Boolean,
    structure: Boolean,
  },
  components: { EditorJSComponent, ImgComponent, IconComponent },
};
</script>

<style lang="stylus">
.person {
  display grid
  grid-template-columns 250px 1fr
  grid-gap 30px
  align-items start
  +below(768px) {
    grid-template-columns 1fr
    grid-gap 15px
  }

  &--small {
    grid-template-columns 205px 1fr
    +below(768px) {
      grid-template-columns 110px 1fr
    }
    +below(420px) {
      display: flex;
      flex-direction: column;
    }

    & ^[0]__image {
      width 205px
      height: 250px;
      +below(768px) {
        width 110px
        height 130px
      }
    }

    .person__name {
      font-weight: 500;
      font-size: 1.5em;
      line-height: 34px;
      color: var(--dark);
      padding-bottom 5px
      margin-bottom 5px
      border-bottom: 1px solid var(--gray-dark);
      +below(768px) {
        font-size: 1.125em;
        line-height: 28px;
      }
    }

    .person__title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.125em;
      line-height: 28px;
      color: var(--dark-light);
      margin-bottom 10px
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      +below(768px) {
        font-size: 0.875em;
        line-height: 22px;
      }
    }

    .person__contacts {
      display flex
      flex-direction column
      align-items flex-start
      gap 0

      li {
        display inline-flex

        a {
          display grid
          grid-template-columns 30px auto
          grid-gap 10px
          font-weight: 500;
          font-size: .875em;
          line-height: 28px;
          align-items center
          color: var(--dark);

          .icon {
            width 20px
            height: 20px;
            background var(--white)
            border-radius 100%
            display inline-flex
            justify-content center
            align-items center

            svg {
              width 10px
              height: 10px;

              path {
                fill var(--dark)
              }
            }
          }
        }
      }
    }
  }

  &__image {
    width 250px
    height: 300px;

    img {
      width 100%
      height 100%
      object-fit cover
      border: 1px solid var(--gray-dark);
      border-radius: 10px;
    }
  }

  &__content {
    display grid
  }

  &__name {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 34px;
    color: var(--dark);
    padding-bottom 20px
    margin-bottom 20px
    border-bottom: 1px solid var(--gray-dark);
    +below(768px) {
      padding-bottom 15px
      margin-bottom 15px
      font-size: 1.125em;
      line-height: 28px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark-light);
    margin-bottom 20px
    +below(768px) {
      margin-bottom 15px
      font-size: 0.875em;
      line-height: 22px;
    }
  }

  &__contacts {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 30px

    li {
      display inline-flex

      a {
        display grid
        grid-template-columns 40px auto
        grid-gap 15px
        font-weight: 500;
        font-size: 1.125em;
        line-height: 28px;
        align-items center
        color: var(--dark);

        .icon {
          width 40px
          height: 40px;
          background var(--white)
          border-radius 100%
          display inline-flex
          justify-content center
          align-items center

          svg {
            width 18px
            height: 18px;

            path {
              fill var(--dark)
            }
          }
        }
      }
    }
  }

  &__biography {
    display grid
    padding 20px 0 0
    grid-gap 20px
  }

  &__biography-title {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 38px;
    color: var(--dark);
    +below(768px) {
      font-size 1em
      line-height 26px
    }
  }

  &__biography-content {
    white-space break-spaces
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    +below(768px) {
      font-size 0.875em
      line-height 24px
    }
  }
}
</style>
