<template>
  <section class="ministry-management">
    <PersonComponent :data="minister" />
    <div class="ministry-management__list" v-if="workersWithoutSection.length">
      <PersonComponent small v-for="(person, i) in workersWithoutSection" :key="i" :data="person" />
    </div>
    <!--    <div v-if="$route.meta.structure">-->
    <!--      <template v-for="(section, i) in sections.filter((s) => s.workers.length)">-->
    <!--        <h2 :key="i" class="ministry-management__title">{{ section.title }}</h2>-->
    <!--        <div class="ministry-management__list" :key="`key-${i}`">-->
    <!--          <PersonComponent-->
    <!--            small-->
    <!--            v-for="(person, index) in workers-->
    <!--              .filter((s) => s.section)-->
    <!--              .filter((s) => s.section.id === section.id)"-->
    <!--            :key="index + i"-->
    <!--            :data="person"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </div>-->
  </section>
</template>

<script>
import PersonComponent from "components/PersonComponent.vue";
import MINISTRY_MANAGEMENT_PAGE from "gql/pages/MinistryManagementPage.graphql";

export default {
  name: "MinistryManagementPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: MINISTRY_MANAGEMENT_PAGE,
        // variables: {
        //   post_type: route.meta.management ? 32 : 33, // 32 - Руководств, 33 - Структура
        //   // management: this.$route.meta.management,
        //   // structure: this.$route.meta.structure,
        // },
      })
      .then(({ data }) => {
        store.dispatch("ministry/save", data);
      })
      .catch(() => {});
  },
  computed: {
    minister() {
      return this.$store.state.minister;
    },
    // sections() {
    //   // if (this.$route.meta.management) {
    //   //   return this.$store.state.ministry.sections.map((s) => s.workers.filter((w) => w.post_type.id === 32));
    //   // } else {
    //   //   return this.$store.state.ministry.sections.map((s) => s.workers.filter((w) => w.post_type.id === 32));
    //   // }
    //   return this.$store.state.ministry.sections;
    // },
    workers() {
      return this.$store.state.ministry.workers.filter((v) => !!v);
    },
    workersWithoutSection() {
      return this.$store.state.ministry.workers.filter(
        (v) => !!v && !v.section && !!v.name && v.id !== this.minister.id
      );
    },
  },
  metaInfo: {
    title: "Руководство",
  },
  components: { PersonComponent },
};
</script>

<style lang="stylus">
.ministry-management {
  display grid
  grid-gap 50px
  +below(768px) {
    grid-gap 30px
  }

  &__list {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(1024px) {
      grid-template-columns 1fr
    }
    +below(768px) {
      grid-gap 15px
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 34px;
    color: var(--dark);
    margin 0
    +below(768px) {
      font-size: 1.125em;
      line-height: 28px;
    }
  }
}
</style>
